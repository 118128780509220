import React from "react"
import { navigate } from "gatsby"
import { getUser, handleLogin, isLoggedIn, updatePassword, logout } from "../services/auth"
import SEO from "./seo"
import { withApollo } from "react-apollo"
import { validatePassword } from "./auth/passwordUtils"
import { API_KEY } from "../constants"
import PermissionsProvider, { HPM_ROLE } from "../providers/permissions-provider";

export function handleNavAfterLogin() {
	console.log(`Handling nav...`);
	const user = getUser();

	let params = new URLSearchParams(window.location.search);
	let override = params.get('override');

	if (PermissionsProvider.hasRole(user, HPM_ROLE.Corporate)) {
		navigate(override ? `/app/reports?override=${override}` : `/app/reports`);

	} else if (
		PermissionsProvider.hasRole(user, HPM_ROLE.Finance)
		|| PermissionsProvider.hasRole(user, HPM_ROLE.Operations)
		|| PermissionsProvider.hasRole(user, HPM_ROLE.Education)
		|| PermissionsProvider.hasRole(user, HPM_ROLE.Nursing)
		|| PermissionsProvider.hasRole(user, HPM_ROLE.Equipment)
	) {
		navigate(override ? `/app/reports?override=${override}` : `/app/reports`);

	} else if (
		PermissionsProvider.hasRole(user, HPM_ROLE.Regional)
		|| PermissionsProvider.hasRole(user, HPM_ROLE.Scheduling)
		|| PermissionsProvider.hasRole(user, HPM_ROLE.SLT)
	) {
		navigate(override ? `/app/dashboards?override=${override}` : `/app/dashboards`);

	} else {
		navigate(override ? `/app/chargesheet?override=${override}` : `/app/chargesheet`);
	}
}

class Login extends React.Component {
	state = {
		username: '',
		password: '',
		errors: false,
		changePassword: false,
		newPassword: '',
		confirmPassword: '',
		validPassword: false,
		validPasswordMessage: '',
	}

	handleUpdate = event => {
		if (event.target.name === 'newPassword' || event.target.name === 'confirmPassword') {

			console.log('current state = ', this.state);

			this.setState({
				[event.target.name]: event.target.value,
			}, () => {
				this.setState({
					['validPassword']: this._validatePasswordEntry(),
				});
				console.log('current state = ', this.state);
			});

		} else {
			console.log('handleUpdate => current state = ', this.state);
			this.setState({
				[event.target.name]: event.target.value,
			});
		}
	}

	_validatePasswordEntry() {
		let validation = validatePassword(this.state.newPassword, this.state.confirmPassword);
		if (!validation.allValid) {
			console.log('password validation = ', validation);

			let message = '';
			if (!validation.passwordMatch) {
				message = 'Passwords must match'
			}
			if (!validation.containsSC) {
				message = 'Password must contain a special character (~`!#$%\^&*+=\-)'
			}
			if (!validation.containsUL) {
				message = 'Password must at least 1 upper case letter'
			}
			if (!validation.containsLL) {
				message = 'Password must at least 1 lower case letter'
			}
			if (!validation.containsN) {
				message = 'Password must be at least 1 number'
			}
			if (!validation.contains8C) {
				message = 'Password must be at least 8 characters long'
			}

			this.setState({
				['validPasswordMessage']: message
			});
		} else {
			return true;
		}
		return false;
	}

	handleSubmit = async event => {
		event.preventDefault();

		const params = new URLSearchParams(window.location.search);
		const override = params.get('override');

		let passwordAttemptsString = localStorage.getItem(`hpm.charge.pw.attempts`);
		let passwordAttempts = 0;
		if (!passwordAttemptsString) {
			localStorage.setItem(`hpm.charge.pw.attempts`, `${passwordAttempts}`);
		} else {
			passwordAttempts = parseInt(passwordAttemptsString);
			passwordAttempts++;
			localStorage.setItem(`hpm.charge.pw.attempts`, `${passwordAttempts}`);
		}

		// validate the password
		if (this.state.changePassword) {
			let validation = validatePassword(this.state.newPassword, this.state.confirmPassword);
			if (!validation.allValid) {

			}
		}

		document.getElementById("spinner").classList.remove("hidden");
		if (this.state.changePassword) {
			if (this.state.newPassword === this.state.password) {
				this.setState({ errors: "New password must be different" });
				document.getElementById("spinner").classList.add("hidden");
			}
			else if (this.state.newPassword !== this.state.confirmPassword) {
				this.setState({ errors: "Passwords do not mach" });
				document.getElementById("spinner").classList.add("hidden");
			}
			else {
				updatePassword({ userId: getUser(true).id, newPassword: this.state.newPassword }, this.props.client);
				// navigate(`/app/chargesheet`);
				handleNavAfterLogin();
			}
		}
		else {
			console.log(`login with state = `, this.state);
			let login = await handleLogin(this.state, this.props.client);

			if (login.graphQLErrors) {
				console.log('ERRORS:', login.graphQLErrors);
				this.setState({ errors: login.graphQLErrors[0].message });

				if (passwordAttempts < 4) {
					this.setState({ errors: `${login.graphQLErrors[0].message} - ${5 - passwordAttempts} attempts remaining` });
				} else if (passwordAttempts === 4) {
					alert(`You have 1 attempt remaining before your account is locked.`);
					this.setState({ errors: `Only 1 attempt remaining!` });
				} else {
					this.setState({ errors: 'Too many attempts' });
				}

				document.getElementById("spinner").classList.add("hidden");
				logout(() => navigate(override ? `/app/login?override=${override}` : `/app/login`));
			}
			else if (getUser(true).requirePasswordReset) {
				event.preventDefault();
				console.log("Password reset required...");
				this.setState({ changePassword: true, errors: false }, () => {
					alert('You are required to change your password');
					event.preventDefault();
				});
				document.getElementById("spinner").classList.add("hidden");
			}
			else if (!getUser(true).isActive) {
				console.log("Account no longer active!");
				document.getElementById("spinner").classList.add("hidden");
				alert('Your account has been deactivated. Please contact your administrator.');
				logout();
			}
			else if (getUser(true).isLocked) {
				console.log("Account locked!");
				document.getElementById("spinner").classList.add("hidden");
				alert('Your account has been locked. Please contact your administrator.');
				// logout();
			}
			else if ((Math.abs(new Date() - new Date(getUser(true).passwordDate)) / 36e5 / 24) > 365) { // expire passwords after 1 year
				console.log("Changing password...");
				this.setState({ changePassword: true, errors: false });
				document.getElementById("spinner").classList.add("hidden");
			}
			else {
				localStorage.setItem(`hpm.charge.pw.attempts`, `${0}`);
				localStorage.setItem(API_KEY, login.data.login.token)
				// navigate(`/app/chargesheet`);
				handleNavAfterLogin();
			}
		}
	}

	render() {

		if (!this.state.changePassword && isLoggedIn()) {
			console.log(`Navigating to Charge Sheet...`);
			const user = getUser();

			const params = new URLSearchParams(window.location.search);
			const override = params.get('override');

			if (PermissionsProvider.hasRole(user, HPM_ROLE.Corporate)) {
				navigate(override ? `/app/reports?override=${override}` : `/app/reports`);

			} else if (
				PermissionsProvider.hasRole(user, HPM_ROLE.Finance)
				|| PermissionsProvider.hasRole(user, HPM_ROLE.Operations)
				|| PermissionsProvider.hasRole(user, HPM_ROLE.Education)
				|| PermissionsProvider.hasRole(user, HPM_ROLE.Nursing)
				|| PermissionsProvider.hasRole(user, HPM_ROLE.Equipment)
			) {
				navigate(override ? `/app/reports?override=${override}` : `/app/reports`);

			} else if (
				PermissionsProvider.hasRole(user, HPM_ROLE.Regional)
				|| PermissionsProvider.hasRole(user, HPM_ROLE.Scheduling)
				|| PermissionsProvider.hasRole(user, HPM_ROLE.SLT)
			) {
				navigate(override ? `/app/dashboards?override=${override}` : `/app/dashboards`);

			} else {
				navigate(override ? `/app/chargesheet?override=${override}` : `/app/chargesheet`);
			}

		}

		return (
			<div className="w-full flex items-center justify-center pt-8">
				<SEO title="Login" />
				<div className="max-w-xs">
					<form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" method="post" onSubmit={async event => {
						await this.handleSubmit(event)
					}}>
						<div className="mb-4" style={{ display: (!this.state.changePassword ? "block" : "none") }}>
							<label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
								Username
							</label>
							<input aria-label="username" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" name="username" onChange={this.handleUpdate} placeholder="Email" />
						</div>
						<div className="mb-6" style={{ display: (!this.state.changePassword ? "block" : "none") }}>
							<label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
								Password
							</label>
							<input aria-label="password" className={"shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" + (true ? null : "border-red-500")} type="password" name="password" onChange={this.handleUpdate} placeholder="******************" />
							{this.state.errors ? <p className="text-red-500 text-xs italic">{this.state.errors}</p> : null}
						</div>
						<div className="mb-4" style={{ display: (this.state.changePassword ? "block" : "none") }}>
							<h2 className="block text-blue-700 mb-2">
								Password Reset
							</h2>
						</div>
						<div className="mb-6" style={{ display: (this.state.changePassword ? "block" : "none") }}>
							<label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="newPassword">
								New Password
							</label>
							<input aria-label="newPassword" className={"shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" + (true ? null : "border-red-500")} type="password" name="newPassword" onChange={this.handleUpdate} placeholder="******************" />
						</div>
						<div className="mb-6" style={{ display: (this.state.changePassword ? "block" : "none") }}>
							<label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="confirmPassword">
								Confirm Password
							</label>
							<input aria-label="confirmPassword" className={"shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" + (true ? null : "border-red-500")} type="password" name="confirmPassword" onChange={this.handleUpdate} placeholder="******************" />
							{this.state.errors ? <p className="text-red-500 text-xs italic">{this.state.errors}</p> : null}
							{(this.state.changePassword && !this.state.validPassword) ? <p className="text-red-500 text-xs italic">{this.state.validPasswordMessage}</p> : null}
						</div>
						<div className="flex items-center justify-between">
							<button className="inline-flex items-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
								<svg id="spinner" className="hidden animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
									<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
									<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
								</svg>
								{this.state.changePassword ? "Submit" : "Log In"}
							</button>
						</div>
					</form>
				</div>
			</div>
		)
	}

}
export default withApollo(Login)
