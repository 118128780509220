/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

import { useIdleTimer } from 'react-idle-timer';
import { getUser, isLoggedIn, logout } from "../services/auth";
import { navigate } from "gatsby";
import IdleDialog from "../components/dialogs/idle-dialog";

import { LocationsUnitsProvider } from '../utils/unitUtils';
import { useEffect } from "react"
import PermissionsProvider from "../providers/permissions-provider"

const isBrowser = () => typeof window !== "undefined";

async function checkVersion() {
  if (isBrowser) {
    // check version change every 5 minutes
    console.log(`Checking version...`);
    try {
      let localVersion = localStorage.getItem(`HPM.CHARGE.VERSION`);
      const url = `${window.location.origin}/assets/version.json`;
      console.log(`version url = ${url}`);
      let response = await fetch(url);
      let serverVersion = await response.json();
      console.log(`LOCAL: ${localVersion} | server: ${serverVersion.version}`, serverVersion);

      let params = new URLSearchParams(window.location.search);
      let override = params.get('override');

      if (
        (!override || (override && override !== 'session'))
        && serverVersion && serverVersion.forceRedirect
      ) {
        const user = getUser();
        if (!PermissionsProvider.hasAnyRoles(user, ['Admin', 'Corporate'])) {
          window.location.href = 'https://charge.hpmcloud.com'
        }
      }

      if (override) {
        window.history.replaceState({}, document.title, `${window.location.origin}${window.location.pathname}`);
      }

      if (localVersion) {
        if (localVersion !== serverVersion.version) {

          if (serverVersion.required && serverVersion.requiresLogout && isLoggedIn()) {
            window.alert(`A new app version is available (${serverVersion.version}). Logout and page refresh is required.`)
            localStorage.setItem(`HPM.CHARGE.VERSION`, serverVersion.version);
            logout(() => navigate(`/app/login`));
            window.location.reload();
          }
          else if (serverVersion.required) {
            window.alert(`A new app version is available (${serverVersion.version}). Page refresh is required.`)
            localStorage.setItem(`HPM.CHARGE.VERSION`, serverVersion.version);
            window.location.reload();
          }
          else if (window.confirm(`A new app version is available (${serverVersion.version}). Please refresh the page.`) == true) {
            localStorage.setItem(`HPM.CHARGE.VERSION`, serverVersion.version);
            window.location.reload();
          }

        }
      } else if (serverVersion.version) {
        localStorage.setItem(`HPM.CHARGE.VERSION`, serverVersion.version);
        if (serverVersion.required && serverVersion.requiresLogout && isLoggedIn()) {
          window.alert(`A new app version is available (${serverVersion.version}). Logout and page refresh is required.`)
          localStorage.setItem(`HPM.CHARGE.VERSION`, serverVersion.version);
          logout(() => navigate(`/app/login`));
          window.location.reload();
        }
        else if (serverVersion.required) {
          window.alert(`A new app version is available (${serverVersion.version}). Page refresh is required.`)
          localStorage.setItem(`HPM.CHARGE.VERSION`, serverVersion.version);
          window.location.reload();
        }
      }
    } catch (err) {
      console.error(err);
    }
  }
};

const Layout = ({ children }) => {
  const [version, setVersion] = useState("")

  useEffect(() => {
    if (isBrowser) {
      checkVersion();
      const retrievedVersion = localStorage.getItem("HPM.CHARGE.VERSION");
      if (retrievedVersion) {
        setVersion(retrievedVersion);
      };

      // check version change every 5 minutes
      setInterval(async () => {
        checkVersion();
      }, 5 * 60 * 1000);
    }
  }, []);

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const onPrompt = () => {
    if (isBrowser()) {
      const location = window.location;
      if (!location.pathname.includes('/login')) {
        window.showIdleDialog();
      }
    }
  }

  const onIdle = () => {
    if (isBrowser()) {
      window.hideIdleDialog();
      const location = window.location;
      if (!location.pathname.includes('/login')) {
        logout(() => navigate(`/app/login`));
      }
    }
  }

  const onActive = (event) => {
    console.log('ACTIVE!');
  }

  const idleTimer = useIdleTimer({
    timeout: 60 * 60 * 1000, // 60 minutes
    promptTimeout: 30 * 1000,
    onIdle: onIdle,
    onActive: onActive,
    onPrompt: onPrompt,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange'
    ]
  });

  const isBrowser = () => typeof window !== "undefined";
  if (isBrowser()) {
    window.resetIdleTime = () => {
      console.log(`Resetting idle time...`);
      idleTimer.start();
    };

    LocationsUnitsProvider.refreshData();
  }

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} version={version} />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />
      <div className="px-2"
        style={{
          margin: `0 auto`
        }}
      >

        <main>{children}</main>
        
        {/* <footer className="text-center text-gray-500 text-xs">
          © {new Date().getFullYear()},
          {` `}
          <a href="https://www.holisticpainmanagement.com">Holistic Pain Management</a>
        </footer> */}
      </div>
      <IdleDialog></IdleDialog>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
